import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import "./header.scss";
import { Link } from "gatsby";
import { isBrowser } from "../../services/Utils";

import { store, useGlobalState } from "state-pool";

import accessibilityIcon from "../../images/Wheelchair_symbol_circle.svg";

store.setState("clearSecondStepForm", false);
store.setState("isReservePage", false);

const Header = ({ siteTitle }) => {
  const [
    clearSecondStepForm,
    setClearSecondStepForm,
    updateClearSecondStepForm,
  ] = useGlobalState("clearSecondStepForm");

  const [isReservePage, setSsReservePage, updateIsReservePage] =
    useGlobalState("isReservePage");

  updateClearSecondStepForm(function (clearSecondStepForm) {
    return true;
  });

  function isActive({ isCurrent }) {
    return isCurrent ? { className: "active" } : null;
  }

  const [burgerActive, setBurgerActive] = useState(false);

  const toggleBurger = () => {
    if (burgerActive) {
      setBurgerActive(false);
    } else {
      setBurgerActive(true);
    }
  };

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  const pageScrolled = scrolled ? "scrolled" : "";

  const burger = burgerActive ? "activated" : "";
  return (
    <header className={`header ${pageScrolled}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-4 d-flex align-items-center">
            <Link to="/">
              <StaticImage
                src="../../images/gotBubbles-logo.png"
                width={250}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Gbs logo"
                className="header__logo-image"
              />
            </Link>
          </div>

          <div className="col">
            <div className="navigation d-flex">
              <div className={`main-menu ${burger}`}>
                <Link
                  to="/about"
                  getProps={isActive}
                  onClick={() => {
                    toggleBurger();
                  }}
                >
                  <div className={`navigation__item`}>Brian Lasky</div>
                </Link>
                <Link
                  to="/faq"
                  getProps={isActive}
                  onClick={() => {
                    toggleBurger();
                  }}
                >
                  <div className="navigation__item">Faq</div>
                </Link>
                <Link
                  to="/programs"
                  getProps={isActive}
                  onClick={() => {
                    toggleBurger();
                  }}
                >
                  <div className="navigation__item">Programs</div>
                </Link>
                {isReservePage ? (
                  <div
                    getProps={isActive}
                    onClick={() => {
                      if (isBrowser()) {
                        window.location.reload();
                      }
                      toggleBurger();
                    }}
                  >
                    <div className="navigation__item">Reserve</div>
                  </div>
                ) : (
                  <Link
                    to="/reserve"
                    getProps={isActive}
                    onClick={() => {
                      toggleBurger();
                    }}
                  >
                    <div className="navigation__item">Reserve</div>
                  </Link>
                )}

                <Link
                  to="/contact"
                  getProps={isActive}
                  onClick={() => {
                    toggleBurger();
                  }}
                >
                  <div className="navigation__item">Contact</div>
                </Link>

                <Link
                  className="navigation__accessibility-page-link"
                  to="/accessibility"
                  onClick={() => {
                    toggleBurger();
                  }}
                >
                  <img
                    src={accessibilityIcon}
                    alt="Accessibility statement"
                    className="navigation__accessibility-icon"
                  />
                </Link>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="menu-burger"
                  onClick={() => {
                    toggleBurger();
                  }}
                >
                  <div className="menu-icon d-flex flex-column">
                    <div className="white-bar"></div>
                    <div className="white-bar"></div>
                    <div className="white-bar"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
