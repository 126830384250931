import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "../header/header";
import Footer from "../footer/footer";
import "../../App.scss";
import "./layout.scss";

import { store, useGlobalState } from "state-pool";

store.setState("sessionError1", false);

store.setState("sessionError2", false);

store.setState("sessionError3", false);

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <a id='skip-nav' className='screenreader-text' href='#main-content'>
        Skip to Content
      </a>
      <main id='main-content'>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
