export function getImageUrl(value) {
  if (!value) {
    return null;
  }
  return process.env.PROD !== "false"
    ? "/files/" + value
    : `http://localhost:5070/files/${value}`;
}

export const isBrowser = () => typeof window !== "undefined";

export const scrollToTop = (pixels, behavior) => {
  window.scrollTo({
    top: pixels,
    behavior: behavior,
  });
};
